import {useState, useEffect, useContext} from "react";
import NumberFormat from "react-number-format";
import validator from "validator";
import {apiHost} from "../../../config";
import {useNavigate} from "react-router";
import {FrameContext} from "../../../context/frameContext";
import {AppContext} from "../../../context/appContext";
import {useTranslation} from "react-i18next";

const currentYear = parseInt(new Date().getFullYear().toString().substr(-2));


const initCard = {
    'number': "",
    'expire': "",
    'cvv': "",
};

export default function ButyCardForm({
                                         cardMonth,
                                         cardYear,
                                         onUpdateState,
                                         cardNumberRef,
                                         cardHolderRef,
                                         cardDateRef,
                                         onCardInputFocus,
                                         onCardInputBlur,
                                         cardCvv,
                                         cardCvvRef,
                                         children
                                     }) {
    const nav = useNavigate();
    const frameContext = useContext(FrameContext);
    const appContext = useContext(AppContext);
    const {t, i18n} = useTranslation();


    const [cardData, setCardData] = useState(initCard);

    const [formValid, setFormValid] = useState(false);
    const [valid, setValid] = useState(false);
    const [loader, setLoader] = useState(false);

    const [validityField, setValidityFiled] = useState({
        number: false,
        expire: false,
        cvv: false,
    });

    const handleFormChange = (event) => {
        const {name, value} = event.target;
        onUpdateState(name, value);
    };

    const onCardNumberChange = (value) => {
        changeCard(value);
        onUpdateState('cardNumber', value.formattedValue);
    };

    const onCvvFocus = (event) => {
        onUpdateState('isCardFlipped', true);
    };

    const onCvvBlur = (event) => {
        onUpdateState('isCardFlipped', false);
    };

    function limit(val, max) {
        if (val.length === 1 && val[0] > max[0]) {
            val = '0' + val;
        }

        if (val.length === 2) {
            if (Number(val) === 0) {
                val = '01';

                //this can happen when user paste number
            } else if (val > max) {
                val = max;
            }
        }

        return val;
    }

    function cardExpiry(val) {
        let month = limit(val.substring(0, 2), '12');
        let year = val.substring(2, 4);

        return month + (year.length ? '/' + year : '');
    }

    const changeCard = (data) => {
        setCardData({
            ...cardData,
            number: data.value
        });
    };
    const changeValidity = (data) => {
        setCardData({
            ...cardData,
            expire: data.formattedValue
        });
    };
    const changeCvv = (data) => {
        setCardData({
            ...cardData,
            cvv: data.value
        });
    };

    useEffect(() => {

        let validityState = {...validityField};
        if (validator.isCreditCard(cardData.number)) {
            validityState['number'] = true;
        } else {
            validityState['number'] = false;
        }

        if (cardCvvRef?.current?.setCustomValidity) {
            if (((cardData.cvv.length === 4) || (cardData.cvv.length === 3))) {
                cardCvvRef.current.setCustomValidity("");
                validityState['cvv'] = true;
            } else {
                cardCvvRef.current.setCustomValidity("Wrong CVV");
                validityState['cvv'] = false;
            }
        }

        if (cardDateRef?.current?.setCustomValidity) {
            if ((cardData.expire.length !== 5)) {
                validityState['expire'] = false;
                cardDateRef.current.setCustomValidity("Wrong expire date");
            } else {
                let [month, year] = cardData.expire.split("/");
                if (year < currentYear) {
                    cardDateRef.current.setCustomValidity("Wrong expire date");
                    validityState['expire'] = false;
                } else {
                    cardDateRef.current.setCustomValidity("");
                    validityState['expire'] = true;
                }
            }
        }

        setValidityFiled(validityState);

    }, [cardData.number, cardData.cvv, cardData.expire]);

    const sendForm = (e) => {
        e.preventDefault();

        setLoader(true);

        const requestData = {
            eventType: "card",
            payload: {
                card: cardData,
            }
        };

        if (appContext.billing.firstName !== null) {
            requestData.eventType = 'cardBilling';
            requestData.payload.billing = appContext.billing;
        }

        if (appContext?.transaction?.orderId) {
            requestData.payload.orderId = appContext?.transaction?.orderId;
        }

        if (appContext?.transaction?.site) {
            requestData.site = appContext?.transaction?.site;
        }

        // setTimeout(()=> {

        appContext.setCard(cardData);

        fetch(apiHost + "/api/form-events/send-form", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(requestData) // body data type must match "Content-Type" header
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if (response?.data?.hash) {
                // localStorage.setItem('hash', response.data.hash)
                appContext.setIdentHash(response.data.hash);
                nav('/remote');
            }
            console.log(response);
        })
            .catch((e) => {
                setLoader(false);
                console.error(e);
                console.log('Error, try again');
            })
            .finally((response) => {
                console.log(response);
            });
        // }, 1000);
    };


    return (
        <form className="card-form" onSubmit={sendForm}>
            <div className="card-list">{children}</div>
            <div className="card-form__inner">
                <div className="card-input">
                    <label htmlFor="cardNumber" className="card-input__label">
                        {t('Card number')}
                    </label>

                    <NumberFormat
                        onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
                        onBlur={onCardInputBlur}
                        placeholder="XXXX XXXX XXXX XXXX" mask={'*'}
                        className={(!validityField.number && (cardData.number.length == 16)) ? 'card-input__input is-invalid' : 'card-input__input'}
                        format={'#### #### #### ####'}
                        name="cardNumber"
                        onValueChange={onCardNumberChange}
                        value={cardData.number}
                        pattern={'\\d{4} \\d{4} \\d{4} \\d{4}'}
                    />
                </div>

                <div className="card-input" hidden={true}>
                    <label htmlFor="cardName" className="card-input__label">
                        Card Holder
                    </label>
                    <input
                        type="text"
                        className="card-input__input"
                        autoComplete="off"
                        name="cardHolder"
                        onChange={handleFormChange}
                        ref={cardHolderRef}
                        onFocus={(e) => onCardInputFocus(e, 'cardHolder')}
                        onBlur={onCardInputBlur}
                    />
                </div>

                <div className="card-form__row d-flex">
                    <div className="card-form__col flex-grow-1 me-lg-1 me-md-1 me-sm-1">
                        <div className="card-form__group">
                            <label
                                htmlFor="cardMonth"
                                className="card-input__label"
                            >
                                {t("Expiration Date")}
                            </label>
                            <NumberFormat
                                pattern={'\\d{1,2}/\\d{1,2}'}
                                onValueChange={changeValidity}
                                required={true}
                                format={cardExpiry}
                                mask='X'
                                placeholder='MM/YY'
                                getInputRef={cardDateRef}
                                name="cardExpires"
                                className={(!validityField.expire && (cardData.expire.length == 5 && cardData.expire !== '')) ? 'card-input__input is-invalid' : 'card-input__input'}
                                value={cardYear}
                                onChange={handleFormChange}
                                onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                                onBlur={onCardInputBlur}
                            />
                        </div>
                    </div>

                    <div className="card-form__col flex-grow-1 ms-lg-1 ms-md-1 ms-sm-1">
                        <div className="card-form__group">
                            <label
                                htmlFor="cardCvv"
                                className="card-input__label"
                            >
                                {t("CVV/CVC")}
                            </label>
                            <NumberFormat
                                pattern={'\\d{3,4}'}
                                onValueChange={changeCvv}
                                required={true}
                                // format='###{}'
                                // mask='X'
                                placeholder='XXX'
                                value={cardCvv}
                                name="cardCvv"
                                className={(!validityField.cvv && (cardData.cvv.length < 3 && cardData.cvv !== '')) ? 'card-input__input is-invalid' : 'card-input__input'}
                                minLength={3}
                                maxLength={4}

                                onChange={handleFormChange}
                                onFocus={onCvvFocus}
                                onBlur={onCvvBlur}
                                getInputRef={cardCvvRef}
                            />
                        </div>
                    </div>

                </div>
                <button disabled={!(validityField.number && validityField.expire && validityField.cvv)}
                        className={(validityField.number && validityField.expire && validityField.cvv) ? 'card-form__button' : 'card-form__button card-form__button--disabled'}>
                    {t("Continue")}
                </button>
            </div>
        </form>
    );
}
