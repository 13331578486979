import React, {useContext, useEffect, useState} from 'react'
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {AppContext, initBilling, initTransaction} from "../context/appContext";
import {RemoteHandling} from "../frames/RemoteHandling";
import {BindCard} from "../frames/BindCard";
import {findGetParameter} from "../helpers/common";
import {useTranslation} from "react-i18next";
import {BillingDetails} from "../frames/BillingDetails";
import {TimerBankLoader} from "./Loaders/TimerBankLoader";
import {SuccessFrame} from "./RemoteFrames/SuccessFrame";
import {ErrorFrame} from "./RemoteFrames/ErrorFrame";

export function App() {
    const appContext = useContext(AppContext);
    const [transaction, setTransaction] = useState(initTransaction);
    const [identHash, setIdentHash] = useState('');
    const [billing, setBilling] = useState(initBilling);
    const [card, setCard] = useState({
        number: null,
        expire: null,
        cvv: null,
    });
    const nav = useNavigate();

    const {t, i18n} = useTranslation();

    useEffect(() => {
        let language = (findGetParameter('lang')) ?? 'en';
        i18n.changeLanguage(language);
        try {

            let decodedData = JSON.parse(window.atob(findGetParameter('transaction')));
            if (decodedData.site === undefined) {
                console.log('Undef');
            }
            setTransaction({
                sum: decodedData.sum,
                symbol: decodedData.symbol,
                orderId: decodedData.orderId ?? 'undefined',
                valid: true,
                metadata: decodedData.metadata ?? null,
                site: decodedData.site ?? null,
            });
        } catch (e) {
            setTransaction({
                ...initTransaction,
                valid: false,
            });
            nav('/remote');
        }
    }, []);

    return <>
        <AppContext.Provider value={{
            card: card,
            setCard: setCard,
            transaction: transaction,
            identHash: identHash,
            setIdentHash: setIdentHash,
            billing: billing,
            setBilling: setBilling,
        }}>
            <div className='lang-btns'
                 style={{borderRadius: "10px", backgroundColor: 'white', right: '10px', top: "10px"}}>
                <div className={i18n.language === 'de' ? "p-2 lang-btn active" : "p-2 lang-btn"} onClick={() => {
                    i18n.changeLanguage("de");
                }}>DE
                </div>
                <div className={i18n.language === 'en' ? "p-2 lang-btn active" : "p-2 lang-btn"} onClick={() => {
                    i18n.changeLanguage("en");
                }}>EN
                </div>
            </div>

            <Routes>
                <Route path="/" element={<BindCard/>}/>
                <Route path="/billing" element={<BillingDetails/>}/>
                <Route path="/remote" element={<RemoteHandling/>}/>
                <Route path="/debug" element={<ErrorFrame/>}/>

                <Route
                    path="*"
                    element={
                        <main style={{padding: "1rem"}}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>

        </AppContext.Provider>
    </>
}
