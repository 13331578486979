import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AppContext} from "../../../context/appContext";

export function ErrorFrame() {
    const [t, i18n] = useTranslation();
    const appContext = useContext(AppContext);

    return (
        <div className="card-form card-form__inner p-0 d-flex m-auto" style={{
            minHeight: "400px",
        }}>
            <div className='d-flex flex-column justify-content-center align-items-center w-100 h-100'>
                <div className='mb-3 mt-3'>
                    <img className='w-100' width='200' height="200" src="/images/error.svg" alt="Image"/>
                </div>

                <div className='p-2 w-100'>

                    <p className='text-center p-3 mb-0 fs-4 text-uppercase'>
                        {t("Payment was declined")}
                    </p>

                    <a className='card-form__button text-decoration-none d-flex flex-center'
                       href={"https://" + appContext.transaction.site}>{t("Return to shop")}</a>
                </div>
            </div>
        </div>
    );
}
