import {ShowOrderData} from "../../ShowTransactionData";
import {CSSTransition, Transition} from "react-transition-group";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../context/appContext";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as validator from "validator";
// import {validator} from "validator";
import * as Yup from 'yup';
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {useNavigate} from "react-router-dom";


export function InputError({err}) {
    return <div className="text-error">
        {err ? err : null}&nbsp;
    </div>;
}


export function BillingForm({onSubmit}) {
    const appContext = useContext(AppContext);
    const [t, i18n] = useTranslation();
    const nav = useNavigate();
    const [active, setActive] = useState(false);
    let req = t('Required');

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            country: '',
            state: '',
            city: '',
            zip: '',
            address1: '',
            address2: '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .min(2, t('Must be') + ' 2 ' + t('characters or more'))
                .max(15, t('Must be') + ' 15 ' + t('characters or less'))
                .required(req),
            lastName: Yup.string()
                .min(2, t('Must be') + ' 2 ' + t('characters or more'))
                .max(15, t('Must be') + ' 15 ' + t('characters or less'))
                .required(req),
            email: Yup.string()
                .min(5, t('Must be') + ' 5 ' + t('characters or more'))
                .max(40, t('Must be') + ' 40 ' + t('characters or less'))
                .email(t('Invalid email address')).required(req),
            phone: Yup
                .string()
                .min(4, t('Must be') + ' 4 ' + t('characters or more'))
                .max(20, t('Must be') + ' 20 ' + t('characters or less'))
                .test('phone', t('Invalid phone number'), (val) => validator.isMobilePhone(val ?? ''))
                .required(req),
            zip: Yup.number()
                .typeError(t('Must be a number'))
                .min(1, t('Must be') + ' 1 ' + t('characters or more'))
                .max(999999999999, t('Must be') + ' 12 ' + t('characters or less'))
                .required(req),
            country: Yup.string().required(req),
            state: Yup.string().required(req),
            city: Yup.string().min(1, t('Must be') + ' 1 ' + t('characters or more'))
                .max(30, t('Must be') + ' 30 ' + t('characters or less')).required(req),
            address1: Yup.string().min(1, t('Must be') + ' 1 ' + t('characters or more'))
                .max(30, t('Must be') + ' 50 ' + t('characters or less')).required(req),
            address2: Yup.string()
                .max(30, t('Must be') + ' 50 ' + t('characters or less')),

        }),
        onSubmit: values => {
            appContext.setBilling(values);
            let prom = setActive(!active);
            setTimeout(() => {
                nav("/");
            }, 250);
            // alert(JSON.stringify(values, null, 2));
        },
    });


    const sendForm = () => {

    };

    useEffect(() => {
        setActive(true);
    }, []);


    return (
        <>
            <CSSTransition
                classNames="alert"
                timeout={300}
                delay={0}
                unmountOnExit
                in={active}
            >
                <form className="card-form card-form--billing p-1" onSubmit={formik.handleSubmit}>

                    <div className="card-form__inner p-3 d-flex flex-column flex-sm-row ">
                        <div className="flex-grow-1 p-3 border-r">
                            <h2 className='mb-3 mb-sm-5 mb-md-5 mb-lg-5 text-center'>{t('Billing details')}</h2>
                            <div>
                                {/*<ShowOrderData/>*/}
                            </div>
                            <div className='d-flex flex-column flex-lg-row
                            justify-content-between'>
                                <div className='mb-3 flex-grow-1 me-lg-1'>
                                    <div className="form-field">
                                        <div className='form-field__label'>{t("First name")}:</div>
                                        <input
                                            className="form-field__input"
                                            type="text"
                                            id='firstName'
                                            name='firstName'
                                            onChange={formik.handleChange}
                                            value={formik.values.firstName}
                                        />
                                    </div>
                                    <InputError err={formik.errors.firstName}/>
                                </div>
                                <div className=' mb-3 flex-grow-1 ms-lg-1'>
                                    <div className="form-field">
                                        <div className='form-field__label'>{t("Last name")}:</div>
                                        <input
                                            className="form-field__input"
                                            type="text"
                                            id='lastName'
                                            name='lastName'
                                            onChange={formik.handleChange}
                                            value={formik.values.lastName}
                                        />
                                    </div>
                                    <InputError err={formik.errors.lastName}/>
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-lg-row
                            justify-content-between'>
                                <div className=' mb-3 flex-grow-1 me-lg-1'>
                                    <div className="form-field">
                                        <div className='form-field__label'>{t("Email")}:</div>
                                        <input
                                            className="form-field__input"
                                            type="text"
                                            id='email'
                                            name='email'
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                    </div>
                                    <InputError err={formik.errors.email}/>
                                </div>
                                <div className=' mb-3 flex-grow-1 ms-lg-1'>
                                    <div className="form-field">
                                        <div className='form-field__label'>{t("Phone number")}:</div>
                                        <input
                                            className="form-field__input"
                                            type="text"
                                            id='phone'
                                            name='phone'
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                        />
                                    </div>
                                    <InputError err={formik.errors.phone}/>
                                </div>
                            </div>
                            <div className=' mb-4'>
                                <div className="form-field form-field__input--ddown">
                                    <div className='form-field__label'>{t("Country")}</div>
                                    <CountryDropdown
                                        className={'form-field__input'}
                                        type="text"
                                        id='country'
                                        name='country'
                                        onChange={(value) => {
                                            formik.setFieldValue('country', value)
                                        }}
                                        value={formik.values.country}
                                    />
                                </div>
                                <InputError err={formik.errors.country}/>
                            </div>
                            <div className='mb-4'>
                                <div className="form-field form-field__input--ddown">
                                    <div className='form-field__label'>{t("State")}</div>
                                    <RegionDropdown
                                        className={'form-field__input'}
                                        type="text"
                                        id='state'
                                        name='state'
                                        country={formik.values.country}
                                        onChange={(value) => {
                                            formik.setFieldValue('state', value)
                                        }}
                                        value={formik.values.state}
                                    />
                                </div>
                                <InputError err={formik.errors.state}/>
                            </div>
                            <div className='mb-4'>
                                <div className="form-field">
                                    <div className='form-field__label'>{t("City")}</div>
                                    <input
                                        className="form-field__input"
                                        type="text"
                                        id='city'
                                        name='city'
                                        onChange={formik.handleChange}
                                        value={formik.values.city}
                                    />
                                </div>
                                <InputError err={formik.errors.city}/>
                            </div>
                            <div className='mb-4'>
                                <div className="form-field">
                                    <div className='form-field__label'>{t("Postal code")}</div>
                                    <input
                                        className="form-field__input"
                                        type="text"
                                        id='zip'
                                        name='zip'
                                        onChange={formik.handleChange}
                                        value={formik.values.zip}
                                    />
                                </div>
                                <InputError err={formik.errors.zip}/>
                            </div>
                            <div className='d-flex flex-column flex-lg-row
                            justify-content-between'>
                                <div className='mb-3 flex-grow-1 me-lg-1'>
                                    <div className="form-field">
                                        <div className='form-field__label'>{t("Address")} 1</div>
                                        <input
                                            type="text"
                                            className="form-field__input"
                                            id='address1'
                                            name='address1'
                                            onChange={formik.handleChange}
                                            value={formik.values.address1}
                                        />
                                    </div>
                                    <InputError err={formik.errors.address1}/>
                                </div>
                                <div className='mb-3 flex-grow-1 ms-lg-1'>
                                    <div className="form-field">
                                        <div className='form-field__label'>{t("Address")} 2</div>
                                        <input
                                            type="text"
                                            className="form-field__input"
                                            id='address2'
                                            name='address2'
                                            onChange={formik.handleChange}
                                            value={formik.values.address2}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button
                                    className={formik.isValid ? 'card-form__button' : 'card-form__button card-form__button--disabled'}>{t('Submit')}</button>
                            </div>
                        </div>
                        <div className="p-3 d-flex flex-column flex-grow-1">
                            <ShowOrderData/>
                            <p className='w-100 mx-auto text-center p-3'>{t("Please check the correctness of the data after entering")}</p>
                            <div className='my-auto d-none d-sm-block'>
                                <img className='w-100' src="/images/mailbox.svg" alt="Logo"/>
                            </div>
                        </div>

                    </div>
                </form>
            </CSSTransition>
        </>
    );
}
